<<<<<<< HEAD
<template>
  <div class="listpage-container">
    <div class="bgc">
      <div class="title">
        <div class="heartitle">
          <img
            class="companylogin"
            src="../../assets/列表页切图/2-logo@2x.png"
            alt=""
            @click="$router.push('/')"
          />
          <img
            class="positioning"
            @click="$router.push('/map')"
            src="../../assets/列表页切图/dingwei.png"
            alt=""
          />
          <img
            @click="$router.push('/map')"
            class="map"
            src="../../assets/列表页切图/lishiditu.png"
            alt=""
          />
          <div class="titleright">
            <el-input
              class="input"
              placeholder="搜索"
              color="#F3AA19;"
              v-model="queryInfo.resName"
              @keyup.enter.native="onSearch"
            >
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
            <!--            <div class="mylogin">-->
            <!--              <img class="myimg" src="../../assets/首页切图/my.png" alt="" />-->
            <!--              <span class="logintext">请登录</span>-->
            <!--            </div>-->
          </div>
        </div>
        <div class="big-center">
          <div class="leftlist">
            <img
              class="treevine"
              src="../../assets/列表页切图/Tree vine@2x.png"
              alt=""
            />
            <img
              class="modules"
              src="../../assets/列表页切图/Modules.png"
              alt=""
            />
            <div @click="selectModel('260125268738580480', '历史人物')">
              <!-- 放大  历史人物-->
              <img
                v-if="this.queryInfo.functionId == '260125268738580480'"
                class="historicals"
                src="../../assets/列表页切图/lishirenwu@2x.png"
                alt=""
              />
              <img
                v-else
                class="historical"
                src="../../assets/列表页切图/lishirenwu@2x.png"
                alt=""
              />
            </div>
            <!-- 典故故事 -->
            <div @click="selectModel('260125237222580224', '典故故事')">
              <img
                v-if="this.queryInfo.functionId == '260125237222580224'"
                class="alferences"
                src="../../assets/列表页切图/diangu@2x.png"
                alt=""
              />
              <img
                v-else
                class="alference"
                src="../../assets/列表页切图/diangu@2x.png"
                alt=""
              />
            </div>
            <!-- 革命英雄 -->
            <div @click="selectModel('260125264988872704', '革命英雄')">
              <img
                class="revolutions"
                v-if="this.queryInfo.functionId == '260125264988872704'"
                src="../../assets/列表页切图/Revolution@2x.png"
                alt=""
              />
              <img
                v-else
                class="revolution"
                src="../../assets/列表页切图/Revolution@2x.png"
                alt=""
              />
            </div>
            <!-- 经典故事 -->
            <div @click="selectModel('260125225902153728', '经典故事')">
              <img
                v-if="this.queryInfo.functionId == '260125225902153728'"
                class="classics"
                src="../../assets/列表页切图/Classic@2x.png"
                alt=""
              />
              <img
                v-else
                class="classic"
                src="../../assets/列表页切图/Classic@2x.png"
                alt=""
              />
            </div>
            <!-- 历史故事 -->
            <div @click="selectModel('260125246395523072', '历史故事')">
              <img
                v-if="this.queryInfo.functionId == '260125246395523072'"
                class="historys"
                src="../../assets/列表页切图/history@2x.png"
                alt=""
              />
              <img
                v-else
                class="history"
                src="../../assets/列表页切图/history@2x.png"
                alt=""
              />
            </div>
            <!-- 一带一路 -->
            <div @click="selectModel('260125270852509696', '一带一路')">
              <img
                class="oneAreas"
                v-if="this.queryInfo.functionId == '260125270852509696'"
                src="../../assets/列表页切图/Onearea@2x.png"
                alt=""
              />
              <img
                v-else
                class="oneArea"
                src="../../assets/列表页切图/Onearea@2x.png"
                alt=""
              />
            </div>
            <!-- 榜样人物 -->
            <div @click="selectModel('260125261838950400', '榜样人物')">
              <img
                class="roleModels"
                v-if="this.queryInfo.functionId == '260125261838950400'"
                src="../../assets/列表页切图/roleModels@2x.png"
                alt=""
              />
              <img
                v-else
                class="roleModel"
                src="../../assets/列表页切图/roleModels@2x.png"
                alt=""
              />
            </div>
            <!-- 智慧故事 -->
            <div @click="selectModel('260125252359823360', '智慧故事')">
              <img
                class="wisdoms"
                v-if="this.queryInfo.functionId == '260125252359823360'"
                src="../../assets/列表页切图/Wisdom@2x.png"
                alt=""
              />

              <img
                v-else
                class="wisdom"
                src="../../assets/列表页切图/Wisdom@2x.png"
                alt=""
              />
            </div>
          </div>
          <div class="rightlist">
            <div
              class="box"
              v-for="(item, index) in reslist"
              :key="index"
              @click="toplayInfo(item)"
            >
              <img class="centimg" :src="url + item.imgUrl" alt="" />
              <div class="botmbox">
                <img
                  class="boxbtn"
                  src="../../assets/列表页切图/v-btn@2x.png"
                  alt=""
                />

                <div class="browse">
                  <!-- <img class="boxbtn" src="../../assets/列表页切图/v-btn@2x.png" alt="" /> -->
                  <img
                    class="Browseimg"
                    src="../../assets/列表页切图/liuran@2x.png"
                    alt=""
                  />
                  <span class="text">{{ item.pageView }}</span>
                </div>
                <div class="browses">
                  <img
                    class="Browseimgs"
                    src="../../assets/列表页切图/dianzan@2x.png"
                    alt=""
                  />
                  <span class="texts">{{ item.collect }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pagination">
          <el-pagination
            background
            hide-on-single-page
            :current-page="this.queryInfo.pageNo"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="this.queryInfo.pageSize"
            layout="prev,pager, next"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="center-bom">
        <img src="../../assets/列表页切图/logo@2x.png" alt="" />
        <div class="bomtext">
          <h6>杭州晨星创文网络科技有限公司</h6>
          <span class="spanone"
            >公司地址：浙江省杭州市钱塘新区佳宝科创中心2幢508室</span
          ><br />
          <span class="spantwo">联系电话：0571-61075056</span>
        </div>
        <img class="opcode" src="../../assets/record.jpg" alt="" />
        <div class="boms">Icp备18038160号-3</div>
      </div>
    </div>
  </div>
</template>

<script>
import Common from "@/components/comm/index";
import { test } from "@/api/test";
import {
  getResList,
  getFuncList,
  queryRes,
  addAccessStatistics,
} from "@/api/home";
import qs from "qs";

export default {
  name: "listpage",
  components: {},
  props: {},
  data() {
    return {
      url: Common.getUrl(),
      value: "",
      queryInfo: {
        functionId: "", //分类id
        resName: "",
        pageNo: 1,
        pageSize: 15,
      },
      datalist: [],
      total: 0,
      reslist: [],
      storylist: [],
    };
  },
  computed: {},
  watch: {},
  created() {
    this.queryInfo.functionId = this.$route.query.functionId;
    this.queryInfo.resName = this.$route.query.resName;
    this.title = this.$route.query.title;
    if (this.queryInfo.resName) {
      this.onSearch();
    } else {
      this.getResLists();
      this.addAccessStatistics(this.title);
    }
  },
  mounted() {},
  methods: {
    async addAccessStatistics(cont) {
      let data = {
        terminal: 1,
        content: cont,
      };
      const { data: res } = await addAccessStatistics(qs.stringify(data));
    },
    onSearch(val) {
      let con = "搜索:" + this.queryInfo.resName;
      this.addAccessStatistics(con);
      queryRes(qs.stringify(this.queryInfo)).then((res) => {
        if (res.data.code === 200) {
          this.reslist = res.data.data.records;
        }
      });
    },
    // 获取资源列表(更多，下部分)
    async getResLists() {
      const { data: res } = await getResList(qs.stringify(this.queryInfo));
      if (res.code == 200) {
        this.reslist = res.data.records;
        this.total = res.data.total;
      } else {
        this.$message("该模块暂未开通");
      }
    },
    // 上部分
    async getFuncLists(data) {
      const { data: res } = await getFuncList(data);
      this.storylist = res.data.functions;
    },
    selectModel(item, title) {
      this.queryInfo.functionId = item;
      this.queryInfo.pageNo = 1;
      this.total = this.reslist.length;
      this.getResLists();
      this.addAccessStatistics(title);
    },
    // 监听pageSizw
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.pageNo = 1;
      this.getResLists();
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pageNo = newPage;
      this.getResLists();
    },
    // prevPage() {
    //   this.pageInfo.pageNo = this.pageInfo.pageNo - 1;
    //   this.datalist = this.list[this.id].resList.slice(
    //     (this.pageInfo.pageNo - 1) * this.pageInfo.pageSize,
    //     this.pageInfo.pageNo * this.pageInfo.pageSize
    //   );
    // },
    // nextPage() {
    //   this.pageInfo.pageNo = this.pageInfo.pageNo + 1;
    //   this.datalist = this.list[this.id].resList.slice(
    //     (this.pageInfo.pageNo - 1) * this.pageInfo.pageSize,
    //     this.pageInfo.pageNo * this.pageInfo.pageSize
    //   );
    // },
    // currentPage(data) {
    //   this.pageInfo.pageNo = data;
    //   this.datalist = this.list[this.id].resList.slice(
    //     (this.pageInfo.pageNo - 1) * this.pageInfo.pageSize,
    //     this.pageInfo.pageNo * this.pageInfo.pageSize
    //   );
    // },
    toplayInfo(item) {
      console.log(item);
      this.$router.push({
        path: "/videoPlay",
        query: {
          resId: item.id,
          collect: item.collect,
          pageView: item.pageView,
          functionId: this.queryInfo.functionId,
          title: item.resName,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.bgc {
  width: 100vw;
  height: 1500px;
  background: url("../../assets/列表页切图/bgc.png");
  background-size: 100% 100%;
}
.title {
  width: 1148px;
  height: 1500px;
  margin: 0 auto;
}
.companylogin {
  width: 160px;
  height: 60px;
  margin-top: -20px;
}
.heartitle {
  position: relative;
  width: 100%;
  padding-top: 20px;
  .positioning {
    position: absolute;
    top: 45%;
    left: 25.5%;
    animation: myfirst 2s infinite;
    width: 20px;
    height: 29px;
  }
  @keyframes myfirst {
    0% {
      transform: translate(0px, 0px);
    }
    50% {
      transform: translate(0px, -12px);
    }
    100% {
      transform: translate(0px, 0px);
    }
  }
}
.map {
  margin-left: 7%;
  margin-top: 1%;
  width: 112px;
  height: 79px;
}
.titleright {
  position: absolute;
  top: 25px;
  right: 10px;
  width: 270px;
  height: 84px;
}
/deep/input::-webkit-input-placeholder {
  color: #f3aa19;
  font-weight: 400;
  font-size: 16px;
}
/deep/input::-moz-input-placeholder {
  color: #f3aa19;
  font-size: 16px;
  font-weight: 400;
}
/deep/input::-ms-input-placeholder {
  color: #f3aa19;
  font-size: 16px;
  font-weight: 400;
}
.input {
  width: 180px;
  height: 46px;
  opacity: 0.52;
  /deep/.el-icon-search:before {
    padding-left: 30px;
    color: #f3aa19;
    font-size: 18px;
    line-height: 46px;
  }
  /deep/.el-input__inner {
    border-radius: 20px;
    padding: 20px;
    text-align: center;
    color: #f3aa19;
    line-height: 47px;
    padding-left: 58px;
  }
}
.mylogin {
  position: absolute;
  top: 0;
  right: 2px;
  width: 48px;
  height: 85px;
  .myimg {
    width: 46px;
    height: 46px;
  }
  .logintext {
    width: 48px;
    height: 16px;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    line-height: 36px;
  }
}
.big-center {
  position: relative;
  width: 100%;
  height: 1300px;
}
.modules {
  position: absolute;
  top: 0;
  left: 0;
  width: 176px;
  height: 127px;
}
.treevine {
  height: 1254px;
  margin-top: 137px;
  margin-left: 20%;
}
.leftlist {
  width: 172px;
  height: 1390px;
  position: relative;
  .historical {
    position: absolute;
    top: 90px;
    left: 28px;
    width: 124px;
    height: 117px;
  }
  .historicals {
    position: absolute;
    top: 100px;
    left: 48px;
    width: 124px;
    height: 117px;
  }
  .historicals:hover {
    position: absolute;
    top: 100px;
    left: 48px;
    width: 124px;
    height: 117px;
  }
  .historical:hover {
    position: absolute;
    top: 100px;
    left: 48px;
    width: 124px;
    height: 117px;
  }
  .alference {
    position: absolute;
    top: 16%;
    left: 28px;
    width: 122px;
    height: 130px;
  }
  .alferences:hover {
    position: absolute;
    top: 16%;
    left: 52px;
    width: 122px;
    height: 130px;
  }
  .alferences {
    position: absolute;
    top: 16%;
    width: 122px;
    height: 130px;
    left: 48px;
  }
  .alference:hover {
    position: absolute;
    top: 16%;
    left: 42px;
    width: 122px;
    height: 130px;
  }
  .revolution {
    position: absolute;
    top: 27%;
    left: 28px;
    width: 121px;
    height: 122px;
  }
  .revolutions {
    position: absolute;
    top: 27%;
    left: 48px;
    width: 121px;
    height: 122px;
  }
  .revolution:hover {
    position: absolute;
    top: 27%;
    left: 48px;
    width: 121px;
    height: 122px;
  }
  .revolutions:hover {
    position: absolute;
    top: 27%;
    left: 48px;
    width: 121px;
    height: 122px;
  }
  .classic {
    position: absolute;
    top: 38%;
    left: 28px;
    width: 121px;
    height: 132px;
  }
  .classics {
    position: absolute;
    top: 38%;
    left: 48px;
    width: 121px;
    height: 132px;
  }
  .classic:hover {
    position: absolute;
    top: 38%;
    left: 46px;
    width: 121px;
    height: 132px;
  }
  .classics {
    position: absolute;
    top: 38%;
    left: 46px;
    width: 121px;
    height: 132px;
  }
  .classic:hover {
    position: absolute;
    top: 38%;
    left: 46px;
    width: 121px;
    height: 132px;
  }
  .history {
    position: absolute;
    top: 49%;
    left: 28px;
    width: 121px;
    height: 131px;
  }
  .history:hover {
    position: absolute;
    top: 49%;
    left: 46px;
    width: 121px;
    height: 131px;
  }
  .historys {
    position: absolute;
    top: 49%;
    left: 48px;
    width: 121px;
    height: 131px;
  }
  .historys:hover {
    position: absolute;
    top: 49%;
    left: 46px;
    width: 121px;
    height: 131px;
  }
  .oneArea {
    position: absolute;
    top: 62.5%;
    left: 28px;
    width: 121px;
    height: 104px;
  }
  .oneArea:hover {
    position: absolute;
    top: 62.5%;
    left: 46px;
    width: 121px;
    height: 104px;
  }
  .oneAreas {
    position: absolute;
    top: 62.5%;
    left: 48px;
    width: 121px;
    height: 104px;
  }
  .oneArea:hover {
    position: absolute;
    top: 62.5%;
    left: 46px;
    width: 121px;
    height: 104px;
  }
  .roleModel {
    position: absolute;
    top: 74%;
    left: 28px;
    width: 121px;
    height: 127px;
  }
  .roleModels:hover {
    position: absolute;
    top: 74%;
    left: 46px;
    width: 121px;
    height: 127px;
  }
  .roleModels {
    position: absolute;
    top: 74%;
    left: 46px;
    width: 121px;
    height: 127px;
  }
  .roleModel:hover {
    position: absolute;
    top: 74%;
    left: 46px;
    width: 121px;
    height: 127px;
  }
  .wisdom {
    position: absolute;
    top: 86%;
    left: 20px;
    width: 121px;
    height: 134px;
  }
  .wisdom:hover {
    position: absolute;
    top: 86%;
    left: 45px;
    width: 121px;
    height: 134px;
  }
  .wisdoms {
    position: absolute;
    top: 86%;
    left: 45px;
    width: 121px;
    height: 134px;
  }
  .wisdoms:hover {
    position: absolute;
    top: 86%;
    left: 45px;
    width: 121px;
    height: 134px;
  }
}
.rightlist {
  position: absolute;
  top: 13px;
  left: 20%;
  width: 939px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
.box {
  position: relative;
  width: 285px;
  height: 230px;
  margin-left: 21px;
  margin-bottom: 20px;
  border-radius: 10px;
  .centimg {
    position: absolute;
    top: 0;
    left: 0;
    width: 285px;
    height: 230px;
    border-radius: 10px;
  }
  .botmbox {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 285px;
    height: 46px;
    background: #ffffff;
    border-radius: 0px 0px 10px 10px;
    .boxbtn {
      position: absolute;
      top: -105px;
      left: 110px;
      width: 55px;
      height: 55px;
    }
    .browse {
      width: 142px;
      height: 100%;
      // backgro{und-color: pink;
      border-radius: 0px 0px 0px 10px;
    }
    .Browseimg {
      position: absolute;
      top: 13px;
      left: 20px;
      width: 32px;
      height: 20px;
    }
    .Browseimgs {
      position: absolute;
      top: 13px;
      left: 20px;
      width: 24px;
      height: 21px;
    }
    .text {
      position: absolute;
      top: 13px;
      left: 64px;
      height: 20px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #c9c9c9;
    }
    .texts {
      position: absolute;
      top: 12px;
      left: 56px;
      height: 20px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #c9c9c9;
    }
    .browses {
      position: absolute;
      top: 0;
      right: 0;
      width: 142px;
      height: 100%;
      border-radius: 0px 0px 10px 0px;
    }
  }
}
.pagination {
  width: 830px;
  margin-left: 22.5%;
}
/deep/.el-pagination {
  text-align: center;
  margin: 10px auto;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #ecbf1c;
}
/deep/.el-pager li {
  margin: 0 10px !important;
  width: 40px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #a8a8a8;
  border-radius: 6px;
  line-height: 40px;
  color: #fff;
}
/deep/.number {
  margin: 0 10px !important;
}
/deep/.btn-next {
  width: 40px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #a8a8a8;
  border-radius: 6px;
  line-height: 40px;
  color: #fff;
}
/deep/.btn-prev {
  width: 40px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #a8a8a8;
  border-radius: 6px;
  line-height: 40px;
  color: #fff;
}
/deep/ .el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  text-align: center;
  line-height: 44px;
  border-style: 1px solid;
}
/deep/.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  line-height: 44px;
}
.bottom {
  width: 100vw;
  height: 298px;
  background: #353535;
  .center-bom {
    position: relative;
    width: 1148px;
    height: 100%;
    margin: 0 auto;
    .botmlogin {
      position: absolute;
      top: 40px;
      left: 0;
    }
    img {
      position: absolute;
      top: 40px;
      width: 156px;
      height: 143px;
    }
    .bomtext {
      position: absolute;
      top: 70px;
      left: 195px;
      width: 534px;
      height: 110px;
      h6 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #868686;
      }
      .spanone {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #878787;
      }
      .spantwo {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #878787;
      }
    }
    .opcode {
      position: absolute;
      right: 0;
      top: 50px;
      width: 140px;
      height: 140px;
      background-color: #fff;
    }
    .boms {
      position: absolute;
      bottom: 0;
      left: 0;
      text-align: center;
      width: 100%;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #868686;
    }
  }
}
</style>